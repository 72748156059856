import React from 'react';
import {Parallax} from 'react-parallax';

const ParallaxAd = (props) => {
    return (

        <Parallax className="bg par-elem ad"
                  blur={0}
                  bgImage={props.image}
                  bgImageAlt={props.imageDescription}
                  strength={200}
        >
            <div className="overlay"></div>
            <div style={{height: '100px'}}/>
            <div className="container list-single-header">
                <div className="list-single-header-item">
                    <div className="list-single-header-item-opt fl-wrap">
                        <div className="list-single-header-cat fl-wrap">
                            <a href="#">{props.locAchat === 'A VENDRE OU A LOUER' ? 'Nous contacter  - €' : props.locAchat === 'A VENDRE' ? new Intl.NumberFormat('fr-FR').format(props.price) + ' €' : new Intl.NumberFormat('fr-FR').format(props.priceLoc) + ' € - ' + props.priceDetailLoc}</a>
                        </div>
                    </div>
                    <h2>{props.type}</h2>
                    <span className="section-separator"></span>
                    <div className="list-post-counter single-list-post-counter"><span>{props.surface} m2</span><i
                        className="fa fa-expand"></i></div>
                    <div className="clearfix"></div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="list-single-header-contacts fl-wrap">
                                <ul>
                                    <li><i className="fas fa-map-marker-alt"></i><a href="#">{props.city}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{height: '50px'}}/>
        </Parallax>

    )
}

export default ParallaxAd;