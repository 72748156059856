import React from 'react';

const AdDescription = (props) => {
    return (
        <div className="list-single-main-item fl-wrap">
            <div className="list-single-main-item-title fl-wrap">
                <h3>Description</h3>
            </div>
            <p>{props.description}</p>
        </div>
    )
}

export default AdDescription;