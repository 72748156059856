import React from 'react';
import ContactForm from '../../components/ContactForm/ContactForm';
import Footer from '../../components/Footer/Footer';
import Menu from '../../components/Menu/Menu';
import ParallaxImage from '../../components/ParallaxImage/ParallaxImage';

const Contact = () => {    
    return (
        <div>
            <Menu />
            <ParallaxImage
                title='Contact'
                subtitle='COORDONNÉES'
                imageName='contact-image.jpg'
            />
            <ContactForm />
            <Footer />
        </div>
    )
}

export default Contact;

