import React, { Component } from 'react';

const Footer = () => {
    return (
        <footer className="main-footer light-footer  ">
            <div className="sub-footer fl-wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="copyright"> &#169; Alliance 2018 .  Tous droits réservés. </div>
                        </div>
                        <div className="col-md-4">
                            <div className="copyright">CGU - Mention légales</div>
                        </div>
                        <div className="col-md-4">

                            <div className="footer-social">
                                <ul>
                                    <li><a href="https://www.facebook.com/cabinetalliance/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-square"></i></a></li>
                                    <li><a href="https://www.linkedin.com/company/alliance---entreprise-&-commerce/about/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;