import React, { Component } from 'react';

class Customer extends Component {
    render() {
        return (
            <section>
                <div className="container">
                    <div className="section-title">
                        <h2>Ils parlent de nous</h2>
                        <span className="section-separator"></span>
                    </div>
                    <div className="carousel">
                        <div className="testimonials-carousel">
                            <div className="slick-slide-item">
                                <div className="testimonilas-text">
                                    <div className="listing-rating card-popup-rainingvis" data-starrating2="5"> </div>
                                    <p>L'équipe d'Alliance est réellement à l'écoute de nos besoins. Elle propose systématiquement des projets ciblés correspondants à nos recherches. Un vrai plaisir de collaboration !!!</p>
                                </div>
                                <div className="testimonilas-avatar-item">
                                    <div className="testimonilas-avatar"><img src="/images/customer/frederic_planquart_kiloutou.jpeg" alt="" /></div>
                                    <h4>Frédéric Planquart</h4>
                                    <span>Ancien directeur du développement Groupe Kiloutou</span>

                                    <div className="logo-customer">
                                        <img src="images/customer/kiloutou.png" alt="" />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <span className="fw-separator"></span>
            </section>
        )
    }
}

export default Customer;