import React, { Component } from 'react';
import AdDescription from '../../components/AdDescription/AdDescription';
import AdPrincipalPhoto from '../../components/AdPrincipalPhoto/AdPrincipalPhoto';
import AdWidget from '../../components/AdWidget/AdWidget';
import Menu from '../../components/Menu/Menu';
import ParallaxAd from '../../components/ParallaxAd/ParallaxAd';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL || 'http://192.168.9.90:8983';

class Ad extends Component {
    state = {
        title: null,
        image: null,
        imageDescription: null,
        description: null,
        price: null,
        surface: null,
        city: null,
        type: null,
        adId: null
    }

    componentDidMount() {
        const endpoint = `${REACT_APP_API_URL}/solr/alliance-data/select?q=reference_number_s:${this.props.match.params.adId}`;
        this.fetchItems(endpoint);
    }

    fetchItems = (endpoint) => {
        fetch(endpoint)
            .then(result => result.json())
            .then(result => {
                this.setState({
                    title: result.response.docs[0].titre_s,
                    image: result.response.docs[0].image_s,
                    imageDescription: result.response.docs[0].image_alt_s,
                    description: result.response.docs[0].description_s,
                    price: parseInt(result.response.docs[0].prix_vente_s),
                    surface: parseInt(result.response.docs[0].surface_s),
                    city: result.response.docs[0].ville_s,
                    type: result.response.docs[0].type_s,
                    adId: result.response.docs[0].reference_number_s,
                    locAchat: result.response.docs[0].loc_achat_s,
                    priceLoc: result.response.docs[0].prix_location_s,
                    priceDetailLoc: result.response.docs[0].prix_detail_location_s
                })
            })
    }

    render() {
        return (
            <div className="content">
                <Menu />
                <ParallaxAd
                    title={this.state.title}
                    image={this.state.image ? this.state.image : './images/thumbnail/no-photo.jpg'}
                    imageDescription={this.state.imageDescription ? 'Alliance - ' + this.state.imageDescription : 'Alliance - Illustration annonce'}
                    price={this.state.price}
                    surface={this.state.surface}
                    city={this.state.city}
                    type={this.state.type}
                    adId={this.state.adId}
                    locAchat={this.state.locAchat}
                    priceLoc={this.state.priceLoc}
                    priceDetailLoc={this.state.priceDetailLoc}

                />
                <section className="gray-section no-top-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="list-single-main-wrapper fl-wrap">
                                    <AdPrincipalPhoto
                                        image={this.state.image ? this.state.image : './images/thumbnail/no-photo.jpg'}
                                        imageDescription={this.state.imageDescription ? 'Alliance - ' + this.state.imageDescription : 'Alliance - Illustration annonce'} />
                                    <AdDescription
                                        description={this.state.description} />
                                </div>
                            </div>
                            <div className="col-md-4">

                                <AdWidget
                                    city={this.state.city}
                                    title={this.state.title}
                                    adId={this.state.adId}
                                    type={this.state.type}
                                    surface={this.state.surface}
                                    locAchat={this.state.locAchat}
                                    price={this.state.price}
                                    priceLoc={this.state.priceLoc}
                                    priceDetailLoc={this.state.priceDetailLoc} />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Ad;