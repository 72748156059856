import React from 'react';
import AdvancedSearchForm from '../../components/AdvancedSearchForm/AdvancedSearchForm';
import Footer from '../../components/Footer/Footer';
import Menu from '../../components/Menu/Menu';
import ParallaxImage from '../../components/ParallaxImage/ParallaxImage';

const Buy = () => {
    return (
        <div>
            <Menu />
            <ParallaxImage
                title='Recherche de bien'
                subtitle='COMMENCER LA RECHERCHE'
                imageName='recherche-image.jpg'
            />
            <AdvancedSearchForm />
            <Footer />
        </div>
    )
}

export default Buy;

