import React, { Component } from 'react';
import Customer from '../../components/Customer/Customer';
import Footer from '../../components/Footer/Footer';
import Menu from '../../components/Menu/Menu';
import ParallaxImage from '../../components/ParallaxImage/ParallaxImage';

class Company extends Component {


    render() {
        return (
            <div>
                <Menu />
                <ParallaxImage
                    title="L'entreprise"
                    subtitle='ALLIANCE'
                    imageName='homepage-image.jpg'
                />
                <section id="sec2">
                    <div className="container">
                        <div className="section-title">
                            <h2>Qui sommes-nous ?</h2>
                            <span className="section-separator"></span>
                            <p>Alliance est un cabinet de conseil à taille humaine et familiale où se côtoient professionnalisme, compétence et bonne humeur. Il est issu de la volonté commune de trois professionnels reconnus sur leurs marchés respectifs de proposer une autre vision du service, celle de la proximité, la transparence et la convivialité, intimement convaincus qu’une relation professionnelle, au même titre qu’une relation personnelle, se doit d’être sincère, entière et durable. Chez nous, le discours « commercial policé, langue de bois et corporate » est banni ! La relation avec nos clients est directe, claire et se fait dans une totale transparence et confiance. </p>
                        </div>
                        <div className="about-wrap">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="video-box fl-wrap">
                                        <img src="./images/team/alliance_bureaux.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="list-single-main-item-title fl-wrap">
                                        <h3><span>Nos métiers</span></h3>
                                        <span className="section-separator fl-sec-sep"></span>
                                    </div>
                                    <p>Alliance, c’est une équipe de spécialistes qui accompagne mandants et preneurs dans deux domaines d’activité : </p>
                                    <ul className="metier-list">
                                        <li><b>+ l’immobilier d’entreprise (bureaux, activité, entrepôts/logistique, montage d’opérations, investissement)</b></li>
                                        <li>+ la cession de fonds de commerce (bar/tabac, brasserie, restauration, hôtellerie)</li>
                                    </ul>
                                    <p></p>
                                    <p>Proches de nos clients, nous analysons avec eux leurs besoins et problématiques et mettons en place la stratégie la plus efficace et adaptée. Nous leur proposons une marche à suivre et les produits et clients adéquats.</p>
                                </div>
                            </div>
                        </div>
                        <span className="fw-separator"></span>
                    </div>
                    <section className="color-bg">
                        <div className="container">
                            <div className="features-box-container fl-wrap row">
                                <div className="section-title-white">
                                    <h2>Nos atouts</h2>
                                    <span className="section-separator"></span>
                                </div>
                                <div className="features-box col-md-6">
                                    <div className="time-line-icon">
                                        <i className="fa fa-users"></i>
                                    </div>
                                    <h3>Connaissance du marché et proximité.</h3>
                                    <p>Nous avons bâti notre savoir-faire sur le terrain, au contact quotidien de nos clients et partenaires locaux. Ce qui nous confère une connaissance pointue de notre marché et de son évolution.</p>
                                </div>

                                <div className="features-box col-md-6">
                                    <div className="time-line-icon">
                                        <i className="fa fa-cogs"></i>
                                    </div>
                                    <h3>Spécialisation et efficacité. </h3>
                                    <p>La taille « humaine » de notre équipe nous donne la réactivité et la capacité d’attention que nos clients attendent. Elle nous donne une souplesse qui nous permet de nous adapter aux demandes spécifiques de nos clients et de mettre en place pour eux, des actions personnalisées sur des secteurs définis, même en dehors de ceux sur lesquels nous intervenons généralement.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Customer />
                    <div className="container">
                        <div style={{ height: '50px' }} />
                        <div className="section-title">
                            <h2>Quelques réalisations</h2>
                            <span className="section-separator"></span>

                        </div>
                        <div className="about-wrap">

                            <div className="row">

                                <div className="col-md-6">
                                    <div className="video-box fl-wrap">
                                        <img src="./images/team/alliance_kiloutou.jpg" alt="" />
                                        <p>L'agence Kiloutou d'Epinay-sur-seine (93)</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="video-box fl-wrap">
                                        <img src="./images/team/alliance_neubauer.jpg" alt="" />
                                        <p>La concession Neubauer de Piscop (95)</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span className="fw-separator"></span>
                    </div>
                    <div className="container">
                        <div style={{ height: '50px' }} />
                        <div className="section-title">
                            <h2>L'équipe</h2>
                            <span className="section-separator"></span>
                            <p></p>
                        </div>
                        <div className="team-holder section-team fl-wrap">
                            <div className="team-box">
                                <div className="team-photo">
                                    <img src="./images/team/alliance_denis_palais.png" alt="" className="respimg" />
                                </div>
                                <div className="team-info">
                                    <h3><a href="#">Denis Palais</a></h3>
                                    <h4>Co-fondateur</h4>
                                    <p>Immobilier d’Entreprise</p>
                                </div>
                            </div>

                            <div className="team-box">
                                <div className="team-photo">
                                    <img src="./images/team/alliance_romeo_chic.png" alt="" className="respimg" />
                                </div>
                                <div className="team-info">
                                    <h3><a href="#">Roméo Chic</a></h3>
                                    <h4>Co-fondateur</h4>
                                    <p>Cession de fonds de commerce</p>
                                </div>
                            </div>

                            <div className="team-box">
                                <div className="team-photo">
                                    <img src="./images/team/alliance_nicolas_yalap.png" alt="" className="respimg" />
                                </div>
                                <div className="team-info">
                                    <h3><a href="#">Nicolas Yalap</a></h3>
                                    <h4>Co-fondateur</h4>
                                    <p>Immobilier d’entreprise/cession de fonds de commerce</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <Footer />
            </div>

        )
    }
}

export default Company;