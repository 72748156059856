import React from 'react';
import FooterHome from '../../components/FooterHome/FooterHome';
import LastAds from '../../components/LastAds/LastAds';
import Menu from '../../components/Menu/Menu';
import ParallaxHomepage from '../../components/ParallaxHomepage/ParallaxHomepage';

const Home = () => {
    return (
        <div>
            <Menu />
            <ParallaxHomepage />
            <LastAds />
            <FooterHome />
        </div>
    )
}

export default Home;