import React, { Component } from 'react';
import ListSearch from '../ListSearch/ListSearch';
import MultiSelectInputSearch from '../MultiSelectInputSearch/MultiSelectInputSearch';
import AsyncMultiSelect from '../AsyncMultiSelect/AsyncMultiSelect';
import AdThumb from '../AdThumb/AdThumb';
import ListActivity from "../ListActivity/ListActivity";

const locAchatKey = 'loc_achat_s'
const typeKey = 'type_s'
const activity = [
    { value: 'Bar/Restaurant', label: 'Bar/Restaurant' },
    { value: 'Tabac', label: 'Tabac' },
]

const REACT_APP_API_URL = process.env.REACT_APP_API_URL || 'http://192.168.9.90:8983';

class AdvancedSearchForm extends Component {
    state = {
        annonces: [],
        resultNumber: 0,
        [locAchatKey]: [],
        [typeKey]: [],
        formControls: {
            locAchat: {
                value: localStorage.getItem('locAchat') ? localStorage.getItem('locAchat') : ''
            },
            type: {
                value: localStorage.getItem('type') ? localStorage.getItem('type') : ''
            },
            activity: {
                value: localStorage.getItem('activity') ? localStorage.getItem('activity') : ''
            },
            priceMin: {
                value: ''
            },
            priceMax: {
                value: localStorage.getItem('priceMax') ? localStorage.getItem('priceMax') : ''
            },
            surfaceMin: {
                value: localStorage.getItem('surfaceMin') ? localStorage.getItem('surfaceMin') : ''
            },
            surfaceMax: {
                value: localStorage.getItem('surfaceMax') ? localStorage.getItem('surfaceMax') : ''
            },
            keywords: {
                value: localStorage.getItem('keywords') ? localStorage.getItem('keywords') : []
            },
            cities: {
                value: localStorage.getItem('cities') ? localStorage.getItem('cities') : []
            },
            citiesSaved: localStorage.getItem('citiesSaved') ? localStorage.getItem('citiesSaved') === '' ? [] : JSON.parse(localStorage.getItem('citiesSaved')) : [],
            keywordsSaved: localStorage.getItem('keywordsSaved') ? localStorage.getItem('keywordsSaved') === '' ? [] : JSON.parse(localStorage.getItem('keywordsSaved')) : [],
        }
    }

    componentDidMount() {
        const endpointLocAchat = `${REACT_APP_API_URL}/solr/alliance-data/select?fl=${locAchatKey}&q=*:*&group=true&group.field=${locAchatKey}&rows=100&start=0`;
        const endpointType = `${REACT_APP_API_URL}/solr/alliance-data/select?fl=${typeKey}&q=*:*&group=true&group.field=${typeKey}&rows=100&start=0`;
        this.fetchItems(endpointLocAchat, locAchatKey);
        this.fetchItems(endpointType, typeKey);
        this.searchResult();
    }

    fetchItems = (endpoint, name) => {
        fetch(endpoint)
            .then(result => result.json())
            .then(result => result.grouped[name].groups)
            .then(result => result.map(element => Object.assign({ value: element.groupValue }, { label: element.groupValue })))
            .then(result => {
                this.setState({
                    [name]: [...this.state[name], ...result]
                })
            })
    }

    sliceText = (text, count, i) => {

        if (text !== "undefined") {
            return text.slice(0, count) + (((text.length > count)) ? "..." : "");
        } else {
            text = this.state.annonces[i].type_s;
            return text.slice(0, count) + (((text.length > count)) ? "..." : "");
        }
    }

    clearResults = () => {
        this.setState({
            annonces: []
        })
    }

    fetchResult = (endpoint) => {
        this.clearResults()
        fetch(endpoint)
            .then(result => result.json())
            .then(result => {
                this.setState({ resultNumber: result.response.numFound });
                this.setState({
                    annonces: [...this.state.annonces, ...result.response.docs]
                })
            })
    }

    handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setFormControlsState(value, name)
    }

    handleSelect = (value, name) => {
        this.setFormControlsState(value, name)
    }

    handleMultiSelect = (props, name) => {
        const multiSelectValue = []
        if (props.inputValue !== undefined) {
            multiSelectValue.push(props.inputValue)
            props.value.map(element => multiSelectValue.push(element.value))
        }
        else {
            props.map(element => multiSelectValue.push(element.value))
        }
        this.setFormControlsState(multiSelectValue, name)
    }

    handleCityMultiSelect = (props, name) => {
        const multiSelectValue = []
        props.map(element => multiSelectValue.push(element.value))
        this.setFormControlsState(multiSelectValue, name)
    }

    setFormControlsState = (value, name) => {
        this.setState({
            formControls: {
                ...this.state.formControls,
                [name]: {
                    ...this.state.formControls[name],
                    value
                }
            }
        });
    }


    searchResult = () => {
        let citiesQueryValue = localStorage.getItem('cities') ? localStorage.getItem('cities').replace(',', '+') : '';
        let keywordsQueryValue = localStorage.getItem('keywords') ? localStorage.getItem('keywords').replace(',', '+') : '';
        const endpointSearch = `${REACT_APP_API_URL}/solr/alliance-data/select?q=*:*&rows=100&start=0` +
            `&fq=loc_achat_s:${this.state.formControls.locAchat.value}*` +
            `&fq=type_s:${this.state.formControls.type.value}*` +
            `&fq=surface_s:${this.getSurfaceQueryValue(this.state.formControls.surfaceMin.value, this.state.formControls.surfaceMax.value)}` +
            `${this.getPriceQueryValue(this.state.formControls.priceMax.value, this.state.formControls.locAchat.value)}` +
            `&fq=ville_s:${citiesQueryValue.toUpperCase()}*` +
            `&fq=${keywordsQueryValue}*`
        this.fetchResult(endpointSearch)
    }

    handleSubmit = (event) => {
        let formatedCityList = [];
        let formatedKeywordList = [];

        localStorage.setItem('locAchat', this.state.formControls.locAchat.value);
        localStorage.setItem('activity', this.state.formControls.activity.value);
        localStorage.setItem('type', this.state.formControls.type.value);
        localStorage.setItem('priceMin', this.state.formControls.priceMin.value);
        localStorage.setItem('priceMax', this.state.formControls.priceMax.value);
        localStorage.setItem('surfaceMin', this.state.formControls.surfaceMin.value);
        localStorage.setItem('surfaceMax', this.state.formControls.surfaceMax.value);
        localStorage.setItem('keywords', this.state.formControls.keywords.value);
        localStorage.setItem('cities', this.state.formControls.cities.value);
        localStorage.setItem('keywordsSaved', this.state.formControls.keywordsSaved.value);

        if (localStorage.getItem('keywords') !== '') {
            localStorage.getItem('keywords').split(',').forEach(element => {
                formatedKeywordList.push(this.formatValuesForReactSelect(element));
            });
            localStorage.setItem('keywordsSaved', JSON.stringify(formatedKeywordList));
        } else {
            localStorage.setItem('keywordsSaved', []);
        }


        if (localStorage.getItem('cities') !== '') {
            localStorage.getItem('cities').split(',').forEach(element => {
                formatedCityList.push(this.formatValuesForReactSelect(element));
            });
            localStorage.setItem('citiesSaved', JSON.stringify(formatedCityList));
        } else {
            localStorage.setItem('citiesSaved', []);
        }

        if (localStorage.getItem('type') === "Locaux d'activité") {
            localStorage.setItem('locAchat', 'A VENDRE');
        }

        this.searchResult();
        event.preventDefault();
    }

    formatValuesForReactSelect = (text) => {
        let formatedValue = { value: text, label: text }
        return formatedValue
    }

    getSurfaceQueryValue = (surfaceMin, surfaceMax) => {
        let surfaceQueryvalue = ''

        if (surfaceMin && surfaceMax) {
            surfaceQueryvalue = '[ ' + surfaceMin + ' TO ' + surfaceMax + ' ]'
        } else if (surfaceMin) {
            surfaceQueryvalue = '[ ' + surfaceMin + ' TO * ]'
        } else if (surfaceMax) {
            surfaceQueryvalue = '[ * TO ' + surfaceMax + ' ]'
        } else {
            surfaceQueryvalue = '[ * TO * ]'
        }

        return surfaceQueryvalue
    }

    getPriceQueryValue = (priceMax, locAchat) => {
        let priceQueryvalue = ''

        if (priceMax && (locAchat === 'A VENDRE')) {
            priceQueryvalue = '&fq=prix_vente_s:[ * TO ' + priceMax + ' ]'
        } else if (priceMax && (locAchat === 'A LOUER')) {
            priceQueryvalue = '&fq=prix_location_s:[ * TO ' + priceMax + ' ]'
        } else if (priceMax) {
            priceQueryvalue = '&fq=prix_vente_s:[ * TO ' + priceMax + ' ]'
        } else {
            priceQueryvalue = ''
        }

        return priceQueryvalue
    }

    render() {
        return (
            <section className="gray-bg no-pading no-top-padding" id="sec1">
                <div className="col-list-wrap  center-col-list-wrap left-list">
                    <div className="container">
                        <div className="listsearch-maiwrap box-inside fl-wrap">
                            <div className="listsearch-header fl-wrap">
                                <h3>Résultats : <span>{this.state.resultNumber}</span></h3>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="listsearch-input-wrap fl-wrap">
                                    <ListSearch
                                        options={this.state[locAchatKey]}
                                        placeholder='Achat / Location'
                                        isDisabled={this.state.formControls.type.value === "Locaux d'activité" ? true : false}
                                        onSelectValue={this.handleSelect}
                                        value={this.state.formControls.locAchat.value === '' ? this.state.formControls.locAchat.value : { label: this.state.formControls.locAchat.value, value: this.state.formControls.locAchat.value }}
                                    />
                                    <ListSearch
                                        options={this.state[typeKey]}
                                        placeholder='Type de bien'
                                        onSelectValue={this.handleSelect}
                                        value={this.state.formControls.type.value === '' ? this.state.formControls.type.value : { label: this.state.formControls.type.value, value: this.state.formControls.type.value }}
                                    />
                                    <ListActivity
                                        options={activity}
                                        placeholder='Activité'
                                        onSelectValue={this.handleSelect}
                                        value={this.state.formControls.activity.value === '' ? this.state.formControls.activity.value : { label: this.state.formControls.activity.value, value: this.state.formControls.activity.value }}
                                        isDisabled={this.state.formControls.type.value !== "Locaux d'activité" ? true : false}
                                    />
                                </div>
                                <div className="listsearch-input-item">
                                    <i className="mbri-key single-i"></i>
                                    <input name="surfaceMin" type="number" placeholder="Surface min. (m2)" value={this.state.formControls.surfaceMin.value} onChange={this.handleChange} />

                                </div>
                                <div className="listsearch-input-item">
                                    <i className="mbri-key single-i"></i>
                                    <input name="surfaceMax" type="number" placeholder="Surface max. (m2" value={this.state.formControls.surfaceMax.value} onChange={this.handleChange} />
                                </div>
                                <div className="listsearch-input-item">
                                    <i className="mbri-key single-i"></i>
                                    <input name="priceMax" type="number" placeholder="Prix max. (€)" value={this.state.formControls.priceMax.value} onChange={this.handleChange} />
                                </div>
                                <div className="listsearch-input-item">
                                    <i className="mbri-key single-i"></i>
                                    <AsyncMultiSelect
                                        onCityMultiSelectValue={this.handleCityMultiSelect}
                                        placeholder='Villes'
                                        defaultCities={this.state.formControls.citiesSaved}
                                    />
                                </div>
                                <div>
                                    <MultiSelectInputSearch
                                        onMultiSelectValue={this.handleMultiSelect}
                                        placeholder='Mots-clés'
                                        defaultCities={this.state.formControls.keywordsSaved} />
                                </div>
                                <button className="button fs-map-btn" type="submit" value="Submit">Rechercher</button>
                            </form>
                        </div>
                    </div>
                    <div className="col-list-wrap  center-col-list-wrap left-list">
                        <div className="container">
                            <div className="list-main-wrap fl-wrap card-listing">
                                {this.state.annonces.map((element, i) => {
                                    return <AdThumb
                                        key={i}
                                        clickable={true}
                                        image={element.image_s ? element.image_s : './images/thumbnail/no-photo.jpg'}
                                        imageDescription={element.image_alt_s ? 'Alliance - ' + element.image_alt_s : 'Alliance - Illustration annonce'}
                                        title={this.sliceText(String(element.titre_s), 20, i)}
                                        type={element.type_s}
                                        description={this.sliceText(String(element.description_s), 60, i)}
                                        price={parseInt(element.prix_vente_s)}
                                        surface={parseInt(element.surface_s)}
                                        city={element.ville_s}
                                        adId={element.reference_number_s}
                                        locAchat={element.loc_achat_s}
                                        priceLoc={element.prix_location_s}
                                        priceDetailLoc={element.prix_detail_location_s}
                                    />
                                })}
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default AdvancedSearchForm;