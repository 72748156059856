import React from 'react';
import emailjs from 'emailjs-com';
import { useFormik } from 'formik';

const REACT_APP_EMAILJS_SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const REACT_APP_EMAILJS_TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
const REACT_APP_EMAILJS_USER_ID = process.env.REACT_APP_EMAILJS_USER_ID;


const validate = values => {
    const errors = {};
    if (!values.name) {
        errors.name = 'Nom et prénom requis';
    }

    if (!values.company) {
        errors.company = 'Nom de société requis';
    }

    if (!values.email) {
        errors.email = 'Email requis';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Email invalide';
    }

    return errors;
};

const ContactForm = () => {

    const formik = useFormik({
        initialValues: {
            name: '',
            company: '',
            email: '',
            phone: '',
            message: '',
        },
        validate,
        onSubmit: values => {
            emailjs.send(REACT_APP_EMAILJS_SERVICE_ID, REACT_APP_EMAILJS_TEMPLATE_ID, values, REACT_APP_EMAILJS_USER_ID)
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        },
    });

    return (
        <section id="sec1">
            <div className="container">
                { formik.isSubmitting ? <div className="main-register-holder">
                    <div className="main-register fl-wrap">
                        <a className="close-reg" onClick={formik.handleReset}><i className="fa fa-times"></i></a>
                        <h3>Merci !</h3>
                        <div className="prelog-message"></div>
                        <div className="soc-log fl-wrap">
                            <p>Votre message a bien été envoyé. Nous recontacterons dès que possible</p>
                        </div>
                    </div>

                </div> : null }
                <div className="col-md-6">
                    <div className="list-single-main-wrapper fl-wrap">
                        <div className="list-single-main-item-title fl-wrap">
                            <h3>Envoyer un mail à alliance</h3>
                        </div>
                        <div id="contact-form">
                            <div id="message"></div>
                            <form className="custom-form" onSubmit={formik.handleSubmit}>
                                <fieldset>
                                    <label><i className="fa fa-user"></i></label>
                                    <input type="text" name="name" id="name" placeholder="Nom et prénom *" onChange={formik.handleChange}
                                        value={formik.values.name} />
                                    {formik.errors.name ? <p className="error-form">{formik.errors.name}</p> : null}
                                    <div className="clearfix"></div>
                                    <label><i className="fa fa-building"></i></label>
                                    <input type="text" name="company" id="company" placeholder="Société *" onChange={formik.handleChange}
                                        value={formik.values.company} />
                                    {formik.errors.company ? <p className="error-form">{formik.errors.company}</p> : null}
                                    <div className="clearfix"></div>
                                    <label><i className="far fa-envelope"></i>  </label>
                                    <input type="email" name="email" id="email" placeholder="Adresse email *" onChange={formik.handleChange}
                                        value={formik.values.email} />
                                    {formik.errors.email ? <p className="error-form">{formik.errors.email}</p> : null}
                                    <div className="clearfix"></div>
                                    <label><i className="fa fa-phone"></i>  </label>
                                    <input type="text" name="phone" id="phone" placeholder="Téléphone" onChange={formik.handleChange}
                                        value={formik.values.phone} />
                                    <div className="clearfix"></div>
                                    <textarea name="message" id="message" placeholder="Message" onChange={formik.handleChange} value={formik.values.message}></textarea>
                                </fieldset>
                                <button className="btn big-btn color-bg flat-btn" type="submit">Envoyer<i className="fa fa-envelope-open"></i></button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="list-single-main-item fl-wrap">
                            <div className="list-author-widget-contacts">
                                <ul>
                                    <li><span><i className="fas fa-map-marker-alt"></i> Adresse :</span> <a href="#">59 Avenue de l'Europe - 95330 Domont</a></li>
                                    <li><span><i className="fa fa-phone"></i> Téléphone :</span> <a href="#">+33(0)1 34 69 31 87</a></li>
                                    <li><span><i className="far fa-envelope"></i> Email :</span> <a href="#" target="_blank" rel="noopener noreferrer">contact@cabinet-alliance.com</a></li>
                                </ul>
                            </div>
                            <div className="list-widget-social">
                                <ul>
                                    <li><a href="https://www.facebook.com/cabinetalliance/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="https://www.linkedin.com/company/alliance---entreprise-&-commerce/about/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )

}

export default ContactForm;