import React, { Component } from 'react';
import CreatableSelect from 'react-select/lib/Creatable';

const lightGreyAllianceColor = '#F9F9F9';
const darkGreyAllianceColor = '#757575';
const mediumGreyAllianceColor = '#EEEEEE';
const yellowAllianceColor = '#EC8500';

const components = {
    DropdownIndicator: null,
};

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        background: '#fff',
        color: state.isFocused ? lightGreyAllianceColor : 'red',
        'text-align': 'left',
        border: 'none',
        height: '40px',
    }),
    control: (provided, state) => ({
        ...provided,
        color: 'red',
        backgroundColor: lightGreyAllianceColor,
        boxShadow: state.isFocused ? 0 : 0,
        borderColor: state.isFocused
            ? mediumGreyAllianceColor
            : mediumGreyAllianceColor,
        '&:hover': {
            borderColor: state.isFocused
                ? mediumGreyAllianceColor
                : mediumGreyAllianceColor,
        }
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: darkGreyAllianceColor,
    }),

    indicatorSeparator: (provided, state) => ({
        ...provided,
        backgroundColor: lightGreyAllianceColor,
    }),
    input: (provided, state) => ({
        ...provided,
        color: darkGreyAllianceColor,
    }),
    multiValue: (provided, state) => ({
        ...provided,
        color: '#fff',
        backgroundColor: yellowAllianceColor,

    }),
    multiValueLabel: (provided, state) => ({
        ...provided,
        color: '#fff',

    }),
}

const createOption = (label: string) => ({
    label,
    value: label,
});

export default class MultiSelectInputSearch extends Component<*, State> {
    state = {
        inputValue: '',
        value: this.props.defaultCities,
    };
    handleChange = (value: any, actionMeta: any) => {
        console.group('Value Changed');
        console.log(value);
        console.log(`action: ${actionMeta.action}`);
        console.groupEnd();
        this.setState({ value });
        this.props.onMultiSelectValue(value, 'keywords');
    };
    handleInputChange = (inputValue: string) => {
        this.setState({ inputValue });
    };
    handleKeyDown = (event: SyntheticKeyboardEvent<HTMLElement>) => {
        const { inputValue, value } = this.state;
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                console.group('Value Added');
                console.log(value);
                console.groupEnd();
                this.setState({
                    inputValue: '',
                    value: [...value, createOption(inputValue)],
                });
                this.props.onMultiSelectValue(this.state, 'keywords');
                event.preventDefault();
        }
    };
    
    render() {
        const { inputValue, value } = this.state;
        return (
            <div className="listsearch-input-item">
                <i className="mbri-key single-i"></i>
                <CreatableSelect
                    components={components}
                    inputValue={inputValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={this.handleChange}
                    onInputChange={this.handleInputChange}
                    onKeyDown={this.handleKeyDown}
                    placeholder={this.props.placeholder}
                    value={value}
                    styles={customStyles}
                />
            </div>
        );
    }
}





