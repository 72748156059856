import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import Ad from './pages/Ad/Ad';
import AdvancedSearch from './pages/AdvancedSearch/AdvancedSearch';
import Buy from './pages/Buy/Buy';
import Company from './pages/Company/Company';
import Contact from './pages/Contact/Contact';
import Home from './pages/Home/Home';
import Rent from './pages/Rent/Rent';


class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <React.Fragment>
                    <Switch>
                        <Route path="/" component={Home} exact />
                        <Route exact path="/louer" component={Rent} />
                        <Route exact path="/acheter" component={Buy} />
                        <Route exact path="/recherche-avancee" component={AdvancedSearch} />
                        <Route exact path="/entreprise" component={Company} />
                        <Route exact path="/contact" component={Contact} />
                        <Route path="/:adId" component={Ad} exact />
                    </Switch>
                </React.Fragment>
            </BrowserRouter>
        )
    }
}

export default App;
