import React from 'react';
import { Parallax } from 'react-parallax';

const ParallaxImage = (props) => {
    return (
        <div className="content">

            <Parallax className="bg par-elem"
                blur={0}
                bgImage={require('../../images/background/' + props.imageName)}
                bgImageAlt={"Alliance - Illustration " + props.title}
                strength={200}
            >
                <div className="overlay"></div>
                <div style={{ height: '100px' }} />
                <div className="container">
                    <div className="section-title center-align">
                        <h2><span>{props.title}</span></h2>
                        <div className="breadcrumbs fl-wrap"><a href="/">Accueil</a><span>{props.title}</span></div>
                        <span className="section-separator"></span>
                    </div>
                </div>
                <div className="header-sec-link">
                    <div className="container"><a className="custom-scroll-link">{props.subtitle}</a>
                    </div>
                </div>
                <div style={{ height: '120px' }} />
            </Parallax>
        </div>
    )
}

export default ParallaxImage;