import React, { Component } from "react";
import AsyncSelect from "react-select/lib/Async";

const lightGreyAllianceColor = '#F9F9F9';
const darkGreyAllianceColor = '#757575';
const mediumGreyAllianceColor = '#EEEEEE';
const yellowAllianceColor = '#EC8500';

const components = {
    DropdownIndicator: null,
};

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        background: '#fff',
        color: state.isFocused ? yellowAllianceColor : '#292929',
        'text-align': 'left',
        border: 'none',
        height: '40px',
    }),
    control: (provided, state) => ({
        ...provided,
        color: 'red',
        backgroundColor: lightGreyAllianceColor,
        boxShadow: state.isFocused ? 0 : 0,
        borderColor: state.isFocused
            ? mediumGreyAllianceColor
            : mediumGreyAllianceColor,
        '&:hover': {
            borderColor: state.isFocused
                ? mediumGreyAllianceColor
                : mediumGreyAllianceColor,
        }
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: darkGreyAllianceColor,
    }),

    indicatorSeparator: (provided, state) => ({
        ...provided,
        backgroundColor: lightGreyAllianceColor,
    }),
    input: (provided, state) => ({
        ...provided,
        color: darkGreyAllianceColor,
    }),
    multiValue: (provided, state) => ({
        ...provided,
        color: '#fff',
        backgroundColor: yellowAllianceColor,

    }),
    multiValueLabel: (provided, state) => ({
        ...provided,
        color: '#fff',

    }),
}

const filterCities = (inputValue: string) => {
    var endpoint = `https://geo.api.gouv.fr/communes?nom=${inputValue.toLowerCase()}&fields=nom&format=json&geometry=centre`;
    return fetch(endpoint)
        .then(result => result.json())
        .then(result =>
            result.map(element =>
                Object.assign({ value: element.nom }, { label: element.nom })
            )
        );
};

const promiseOptions = inputValue =>
    new Promise(resolve => {
        setTimeout(() => {
            resolve(filterCities(inputValue));
        }, 1000);
    });

export default class AsyncMultiSelect extends Component<*, State> {
    state = {
        inputValue: '',
    };

    handleInputChange = (newValue: string) => {
        const inputValue = newValue.replace(/\W/g, '');
        this.setState({ inputValue });
        return inputValue;
    };

    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
        this.props.onCityMultiSelectValue(selectedOption, 'cities');
    }

    customMessageWhenNoOptionSelected = () => {
        return 'Chercher une ville'
    }

    render() {
        return (
            <div>
                <AsyncSelect
                    isMulti
                    cacheOptions
                    defaultOptions
                    defaultValue={this.props.defaultCities}
                    loadOptions={promiseOptions}
                    styles={customStyles}
                    placeholder={this.props.placeholder}
                    onInputChange={this.handleInputChange}
                    onChange={this.handleChange}
                    components={components}
                    noOptionsMessage={this.customMessageWhenNoOptionSelected}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary50: '#1A193B',
                        },
                    })}
                /></div>
        );
    }
}

