import React, { Component } from 'react';
import { Link } from "react-router-dom";

const FooterHome = () => {
    return (
        <footer className="main-footer dark-footer  ">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="footer-widget fl-wrap">
                            <h3>Qui sommes-nous ?</h3>
                            <div className="footer-contacts-widget fl-wrap">
                                <Link to="/entreprise"><img className="footer-img" src="/images/logo-bottom.png" style={{ float: "left" }} alt="" /></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="footer-widget fl-wrap">
                            <h3>Contact</h3>
                            <div className="footer-contacts-widget fl-wrap">
                                <p>Une question ? Une prise de rendez-vous ? Nous vous invitons à nous contacter.</p>
                                <ul className="footer-contacts fl-wrap">
                                    <li><span><i className="far fa-envelope"></i> Email :</span><a href="mailto:contact@cabinet-alliance.com" target="_blank" rel="noopener noreferrer">contact@cabinet-alliance.com</a></li>
                                    <li> <span><i className="fas fa-map-marker-alt"></i> Adresse :</span><a href="#" target="_blank" rel="noopener noreferrer">59 Avenue de l'Europe - 95330 Domont</a></li>
                                    <li><span><i className="fa fa-phone"></i>Téléphone :</span><a href="#">+33(0)1 34 69 31 87</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sub-footer fl-wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="copyright"> &#169; Alliance 2018 .  Tous droits réservés. </div>
                        </div>
                        <div className="col-md-4">
                            <div className="copyright">CGU - Mention légales</div>
                        </div>
                        <div className="col-md-4">

                            <div className="footer-social">
                                <ul>
                                    <li><a href="https://www.facebook.com/cabinetalliance/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-square"></i></a></li>
                                    <li><a href="https://www.linkedin.com/company/alliance---entreprise-&-commerce/about/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterHome;