import React from 'react';

const AdPrincipalPhoto = (props) => {
    return (
        <div className="list-single-main-media fl-wrap">
            <img src={props.image} className="respimg" alt={props.imageDescription}></img>
        </div>
    )
}

export default AdPrincipalPhoto;