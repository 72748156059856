import React, {Component} from 'react';
import AdThumb from '../AdThumb/AdThumb';

const REACT_APP_API_URL = process.env.REACT_APP_API_URL || 'http://192.168.9.90:8983';

class LastAds extends Component {

    state = {
        annonces: [],
    }

    componentDidMount() {
        const endpoint = `${REACT_APP_API_URL}/solr/alliance-data/select?q=*:*`;
        this.fetchItems(endpoint);
    }

    fetchItems = (endpoint) => {
        fetch(endpoint)
            .then(result => result.json())
            .then(result => {
                this.setState({
                    annonces: [...this.state.annonces, ...result.response.docs.slice(0, 3)]
                })
            })
    }

    sliceText = (text, count, i) => {

        if (text !== "undefined") {
            return text.slice(0, count) + (((text.length > count)) ? "..." : "");
        } else {
            text = this.state.annonces[i].type_s;
            return text.slice(0, count) + (((text.length > count)) ? "..." : "");
        }
    }


    render() {
        return (
            <section className="gray-section">
                <div className="container">
                    <div className="section-title">
                        <h2>Dernières annonces</h2>
                        <span className="section-separator"></span>
                    </div>
                </div>
                <div className="col-list-wrap  center-col-list-wrap left-list">
                    <div className="container">
                        <div className="list-main-wrap fl-wrap card-listing">
                            {this.state.annonces.map((element, i) => {
                                return <AdThumb
                                    key={i}
                                    clickable={true}
                                    image={element.image_s ? element.image_s : './images/thumbnail/no-photo.jpg'}
                                    imageDescription={element.image_alt_s ? 'Alliance - ' + element.image_alt_s : 'Alliance - Illustration annonce'}
                                    title={this.sliceText(String(element.titre_s), 20, i)}
                                    type={element.type_s}
                                    description={this.sliceText(String(element.description_s), 60, i)}
                                    price={parseInt(element.prix_vente_s)}
                                    surface={parseInt(element.surface_s)}
                                    city={element.ville_s}
                                    adId={element.reference_number_s}
                                    locAchat={element.loc_achat_s}
                                    priceLoc={element.prix_location_s}
                                    priceDetailLoc={element.prix_detail_location_s}
                                />
                            })}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default LastAds;