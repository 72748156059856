import React, { Component } from 'react';
import { Parallax } from 'react-parallax';
import ListSearch from '../ListSearch/ListSearch';
import ListActivity from '../ListActivity/ListActivity';
import AsyncMultiSelect from '../AsyncMultiSelect/AsyncMultiSelect';
import { withRouter } from 'react-router-dom';

const locAchatKey = 'loc_achat_s'
const typeKey = 'type_s'
const activityKey = 'activite_s'
const activity = [
    { value: 'Bar/Restaurant', label: 'Bar/Restaurant' },
    { value: 'Tabac', label: 'Tabac' },
]


const REACT_APP_API_URL = process.env.REACT_APP_API_URL || 'http://192.168.9.90:8983';

class ParallaxHomepage extends Component {
    state = {
        [locAchatKey]: [],
        [typeKey]: [],
        [activityKey]: [],
        fondsDeCommercesSelected: false,
        bureauxSelected: false,
        entrepotsSelected: false,
        searchTitle: '',
        formControls: {
            locAchat: {
                value: ''
            },
            type: {
                value: ''
            },
            activity: {
                value: ''
            },
            cities: {
                value: []
            },
            citiesSaved: [],
        }
    }

    componentDidMount() {
        const endpointLocAchat = `${REACT_APP_API_URL}/solr/alliance-data/select?fl=${locAchatKey}&q=*:*&group=true&group.field=${locAchatKey}&rows=100&start=0`;
        const endpointType = `${REACT_APP_API_URL}/solr/alliance-data/select?fl=${typeKey}&q=*:*&group=true&group.field=${typeKey}&rows=100&start=0`;
        this.fetchItems(endpointLocAchat, locAchatKey);
        this.fetchItems(endpointType, typeKey);

        localStorage.setItem('locAchat', '');
        localStorage.setItem('cities', []);
        localStorage.setItem('citiesSaved', []);
        localStorage.setItem('type', '');
        localStorage.setItem('activity', '');
    }

    fetchItems = (endpoint, name) => {
        fetch(endpoint)
            .then(result => result.json())
            .then(result => result.grouped[name].groups)
            .then(result => result.map(element => Object.assign({ value: element.groupValue }, { label: element.groupValue })))
            .then(result => {
                this.setState({
                    [name]: [...this.state[name], ...result]
                })
            })
    }

    handleCityMultiSelect = (props, name) => {
        const multiSelectValue = []
        props.map(element => multiSelectValue.push(element.value))
        this.setFormControlsState(multiSelectValue, name)
    }

    handleSelect = (value, name) => {
        this.setFormControlsState(value, name)
        localStorage.setItem(name, value);
    }

    handleSubmit = (event) => {
        let formatedCityList = [];

        localStorage.setItem('locAchat', this.state.formControls.locAchat.value);
        localStorage.setItem('cities', this.state.formControls.cities.value);

        if (localStorage.getItem('cities') !== '') {
            localStorage.getItem('cities').split(',').forEach(element => {
                formatedCityList.push(this.formatValuesForReactSelect(element));
            });
            localStorage.setItem('citiesSaved', JSON.stringify(formatedCityList));
        } else {
            localStorage.setItem('citiesSaved', []);
        }

        if (localStorage.getItem('type') === "Locaux d'activité") {
            localStorage.setItem('locAchat', 'A VENDRE');
        }

        this.props.history.push('/recherche-avancee');
        event.preventDefault();
    }

    formatValuesForReactSelect = (text) => {
        let formatedValue = { value: text, label: text }
        return formatedValue
    }

    setFormControlsState = (value, name) => {
        this.setState({
            formControls: {
                ...this.state.formControls,
                [name]: {
                    ...this.state.formControls[name],
                    value
                }
            }
        });
    }

    selectFondsDeCommerces = () => {
        this.setFormControlsState([], "type")
        this.setFormControlsState([], "activity")
        this.setFormControlsState('', "locAchat")

        localStorage.setItem('type', '');
        localStorage.setItem('locAchat', '');
        localStorage.setItem('cities', []);
        localStorage.setItem('citiesSaved', []);
        this.setState({ fondsDeCommercesSelected: true });
        this.setState({ bureauxSelected: false });
        this.setState({ entrepotsSelected: false });
        this.setState({ type: "Locaux d'activité" });
        this.setState({ searchTitle: "Fonds de commerces" });
        this.setFormControlsState("Locaux d'activité", "type")
        localStorage.setItem('type', "Locaux d'activité");
    }

    selectBureaux = () => {
        this.setFormControlsState([], "type")
        this.setFormControlsState([], "activity")
        this.setFormControlsState('', "locAchat")

        localStorage.setItem('type', '');
        localStorage.setItem('locAchat', '');
        localStorage.setItem('cities', []);
        localStorage.setItem('citiesSaved', []);
        this.setState({ fondsDeCommercesSelected: false });
        this.setState({ bureauxSelected: true });
        this.setState({ entrepotsSelected: false });
        this.setState({ searchTitle: "Bureaux" });
        this.setFormControlsState("Bureaux", "type")
        localStorage.setItem('type', "Bureaux");
    }

    selectEntrepots = () => {
        this.setFormControlsState([], "type")
        this.setFormControlsState([], "activity")
        this.setFormControlsState('', "locAchat")

        localStorage.setItem('type', '');
        localStorage.setItem('locAchat', '');
        localStorage.setItem('cities', []);
        localStorage.setItem('citiesSaved', []);
        this.setState({ fondsDeCommercesSelected: false });
        this.setState({ bureauxSelected: false });
        this.setState({ entrepotsSelected: true });
        this.setState({ searchTitle: "Entrepôts" });
        this.setFormControlsState("Bureaux et Entrepôts", "type")
        localStorage.setItem('type', "Bureaux et Entrepôts");
    }

    clearResults = () => {
        this.setState({ fondsDeCommercesSelected: false });
        this.setState({ bureauxSelected: false });
        this.setState({ entrepotsSelected: false });
        this.setFormControlsState([], "type")
        this.setFormControlsState([], "activity")
        this.setFormControlsState('', "locAchat")

        localStorage.setItem('type', '');
        localStorage.setItem('locAchat', '');
        localStorage.setItem('activity', '');
        localStorage.setItem('cities', []);
        localStorage.setItem('citiesSaved', []);
    }

    render() {
        return (
            <div className="content">
                <div className="overlay"></div>
                <Parallax className="hero-section"
                    blur={0}
                    bgImage={require('../../images/background/homepage-image.jpg')}
                    bgImageAlt="alliance - illustration bar"
                    strength={200}
                >
                    <div style={{ height: '150px' }} />
                    <div className="hero-section-wrap fl-wrap">
                        <div className="container">
                            <div className="intro-item fl-wrap">
                                <h2>alliance</h2>
                                <h3><strong>Conseil en immobilier d'entreprise et cession de fonds de commerce</strong></h3>
                            </div>
                        </div>

                        <div style={{ height: '60px' }} />

                        <div className="row">
                            <div className="col-md-3"></div>
                            <div className="col-md-2 home">
                                <a href="#" className={"btn-home square-btn center " + (this.state.fondsDeCommercesSelected ? true : false)} onClick={this.selectFondsDeCommerces} role="button"><i className="fas fa-store fa-4x"></i></a>
                                <p className={"p-home " + (this.state.fondsDeCommercesSelected ? true : false)}><strong>Fonds de commerce</strong></p>
                            </div>
                            <div className="col-md-2 home">
                                <a href="#" className={"btn-home square-btn center " + (this.state.bureauxSelected ? true : false)} onClick={this.selectBureaux} role="button"><i className="fa fa-building fa-4x"></i></a>
                                <p className={"p-home " + (this.state.bureauxSelected ? true : false)}><strong>Bureaux</strong></p>
                            </div>
                            <div className="col-md-2 home">
                                <a href="#" className={"btn-home square-btn center " + (this.state.entrepotsSelected ? true : false)} onClick={this.selectEntrepots} role="button"><i className="fas fa-industry fa-4x"></i></a>
                                <p className={"p-home " + (this.state.entrepotsSelected ? true : false)}><strong>Entrepôts</strong></p>
                            </div>
                        </div>
                        {this.state.fondsDeCommercesSelected || this.state.bureauxSelected || this.state.entrepotsSelected ? (
                            <form onSubmit={this.handleSubmit}>
                                <div className="container">
                                    <div className="listsearch-maiwrap box-inside fl-wrap">
                                        <div className="section-title"><h1 style={{ color: "white", fontSize: "18px" }}>{this.state.searchTitle}</h1><span className="section-separator"></span></div>
                                        <a className="close-reg" onClick={this.clearResults}><i className="fa fa-times"></i></a>
                                        <div className="listsearch-input-wrap fl-wrap">
                                            {this.state.formControls.type.value !== "Locaux d'activité" ? (
                                                <ListSearch
                                                    options={this.state[locAchatKey]}
                                                    placeholder='Achat / Location'
                                                    onSelectValue={this.handleSelect}
                                                />) : (<ListActivity
                                                options={activity}
                                                placeholder='Activité'
                                                onSelectValue={this.handleSelect}
                                            />)}

                                            <div className="listsearch-input-item">
                                                <i className="mbri-key single-i"></i>
                                                <AsyncMultiSelect
                                                    onCityMultiSelectValue={this.handleCityMultiSelect}
                                                    placeholder='Villes'
                                                    defaultCities={this.state.formControls.citiesSaved}
                                                />
                                            </div>
                                            <div>
                                                <button className="button fs-home-search-btn" type="submit" value="Submit"> Rechercher </button>
                                            </div>
                                        </div>
                                    </div>
                                </div></form>) : ""}
                    </div>
                    {this.state.fondsDeCommercesSelected || this.state.bureauxSelected || this.state.entrepotsSelected ? (
                        <div style={{ height: '750px' }} />) : (<div style={{ height: '500px' }} />)}
                </Parallax>
            </div >
        )
    }
}

export default withRouter(ParallaxHomepage);