import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import * as serviceWorker from './serviceWorker';
import './index.css';
import Ad from './pages/Ad/Ad';
import AdvancedSearch from './pages/AdvancedSearch/AdvancedSearch';
import App from './App';
import Buy from './pages/Buy/Buy';
import Company from './pages/Company/Company';
import Contact from './pages/Contact/Contact';
import Rent from './pages/Rent/Rent';




const routing = (
  <Router>
    <div>
      <Switch>
        <Route exact path="/" component={App} />
        <Route exact path="/recherche-avancee" component={AdvancedSearch} />
        <Route exact path="/louer" component={Rent} />
        <Route exact path="/acheter" component={Buy} />
        <Route exact path="/entreprise" component={Company} />
        <Route exact path="/contact" component={Contact} />
        <Route path="/:adId" component={Ad} exact />
      </Switch>
    </div>
  </Router>
)
ReactDOM.render(routing, document.getElementById('wrapper'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
