import React from 'react';

const AdWidget = (props) => {
    return (
        <div className="box-widget-wrap">
            <div className="box-widget-item fl-wrap">
                <div className="box-widget">
                    <div className="box-widget-content">
                        <div className="list-author-widget-contacts list-item-widget-contacts">
                            <ul>
                                <li><span><i className="fa fa-building"></i> Type : {props.type}</span>
                                </li>
                                <li><span><i className="fa fa-expand"></i> Surface : {props.surface} m2</span>
                                </li>
                                <li><span><i className="fa fa-map-marker"></i> Ville : {props.city}</span>
                                </li>
                                <li><span><i className="fa fa-handshake-o"></i>{props.locAchat}</span>
                                </li>
                                <li><span><i className="fa fa-eur"></i>{props.locAchat === 'A VENDRE OU A LOUER' ? 'Nous contacter  - € ' : props.locAchat === 'A VENDRE' ? new Intl.NumberFormat('fr-FR').format(props.price) + ' €' : new Intl.NumberFormat('fr-FR').format(props.priceLoc) + ' € - ' + props.priceDetailLoc}</span>
                                </li>
                            </ul>
                        </div>
                        <div className="btn-contact">
                            <a href="#" className={"btn small-btn color-bg flat-btn"} href={"mailto:contact@cabinet-alliance.com?subject=Demande%20d%27information%20pour%20le%20bien%20"+ (props.title) +"%20(ref%3A%20"+(props.adId)+")"+"&body=Bonjour%2C%0AJe%20suis%20int%C3%A9ress%C3%A9%20par%20le%20bien%20%3A%20"+(props.title)+"%20(r%C3%A9f%C3%A9rence%3A%20"+(props.adId)+").%0AMerci%20de%20me%20contacter."} target="_blank" rel="noopener noreferrer" role="button">Contact</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdWidget;