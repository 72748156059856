import React, { Component } from 'react';
import Select from 'react-select';

const yellowAllianceColor = '#EC8500';
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        background: '#fff',
        color: state.isFocused ? yellowAllianceColor : '#292929',
        'text-align': 'left',
        border: 'none',
        height: '40px',
    }),
    control: (provided, state) => ({
        ...provided,
        color: '#fff',
        backgroundColor: state.isDisabled ? '#eee' : yellowAllianceColor,
        boxShadow: state.isFocused ? 0 : 0,
        borderColor: state.isFocused
            ? yellowAllianceColor
            : provided.borderColor,
        '&:hover': {
            borderColor: state.isFocused
                ? yellowAllianceColor
                : provided.borderColor,
        }
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: "#fff",
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: '#fff',
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '#eee' : "#fff",
    }),
    input: (provided, state) => ({
        ...provided,
        color: '#fff',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: '#fff',

    }),
}

class ListSearch extends Component {
    state = {
        selectedOption: null,
        name: this.props.placeholder
    }

    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
        console.log(this.state.name);
        if(this.state.name === 'Type de bien'){
            this.props.onSelectValue(selectedOption.value, 'type');
        } else {
            this.props.onSelectValue(selectedOption.value, 'locAchat');
        }
            
        
    }

    render() {
        return (
            <div className="listsearch-input-item current">
                <Select
                    value={this.props.value}
                    onChange={this.handleChange}
                    styles={customStyles}
                    options={this.props.options}
                    placeholder={this.props.placeholder}
                    isDisabled={this.props.isDisabled}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary50: '#1A193B',
                        },
                    })}
                />
            </div>
        );
    }
}

export default ListSearch;

