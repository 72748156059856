import React from 'react';
import { Link } from 'react-router-dom';

const AdThumb = (props) => {
    return (
        <div className="listing-item">
            <article className="geodir-category-listing fl-wrap">
                <div className="geodir-category-img">
                    {props.clickable ?
                        <Link to={{ pathname: `/${props.adId}` }}>
                            <img src={props.image} alt={props.imageDescription} />
                        </Link>
                        :
                        <img src={props.image} alt={props.imageDescription} />
                    }
                </div>
                <div className="geodir-category-content fl-wrap">
                    <a className="listing-geodir-category"
                        href="listing.html">{props.locAchat === 'A VENDRE OU A LOUER' ? 'Nous contacter  - €' : props.locAchat === 'A VENDRE' ? new Intl.NumberFormat('fr-FR').format(props.price) + ' €' : new Intl.NumberFormat('fr-FR').format(props.priceLoc) + ' € - ' + props.priceDetailLoc}</a>
                    <h3><a href="listing-single.html">{props.type}</a></h3>
                    <p>{props.surface} m2</p>
                    <div className="geodir-category-options fl-wrap">
                        <div className="geodir-category-location"><a href="#"><i className="fas fa-map-marker-alt"
                            aria-hidden="true"></i>{props.city}
                        </a></div>
                    </div>
                </div>
            </article>
        </div>
    )
}

export default AdThumb;