import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Menu extends Component {
    state = {
        isMenuOpen: false,
    }

    openMenu = () => {
        if (this.state.isMenuOpen) {
            this.setState({ isMenuOpen: false });
        } else {
            this.setState({ isMenuOpen: true });
        }
    }

    setRent = () => {
        localStorage.setItem('locAchat', 'A LOUER');
    }

    setBuy = () => {
        localStorage.setItem('locAchat', 'A VENDRE');
    }

    render() {
        return (
            <header className="main-header dark-header fs-header sticky">
                <div className="header-inner">
                    <div className="logo-holder"><a href="/"><img src="images/logo.png" alt="" /></a></div>
                    <a href="./plaquette/alliance-plaquette.pdf" target="_blank" className="add-list">Télécharger la plaquette <span><i className="fa fa-download"></i></span></a>
                    <div className="nav-holder main-menu">
                        <nav>
                            <ul>
                                <li>
                                    <Link to={"/"}>Accueil</Link>
                                </li>
                                <li>
                                    <Link to={"/louer"} onClick={this.setRent}>Louer</Link>
                                </li>
                                <li>
                                    <Link to={"/acheter"} onClick={this.setBuy}>Acheter</Link>
                                </li>
                                <li>
                                    <Link to={"/recherche-avancee"}>Recherche de bien</Link>
                                </li>
                                <li>
                                    <Link to={"/entreprise"}>L'entreprise</Link>
                                </li>
                                <li>
                                    <Link to={"/contact"}>Contact</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>


                    <div className="nav-button-wrap color-bg" onClick={this.openMenu}>
                        <div className="nav-button">
                            <span></span><span></span><span></span>

                        </div>
                        {this.state.isMenuOpen ? (
                            <div className="main-menu vismobmenu">
                                <nav>
                                    <ul>
                                        <li>
                                            <Link to={"/"}>Accueil</Link>
                                        </li>
                                        <li>
                                            <Link to={"/louer"}>Louer</Link>
                                        </li>
                                        <li>
                                            <Link to={"/acheter"}>Acheter</Link>
                                        </li>
                                        <li>
                                            <Link to={"/recherche-avancee"}>Recherche de bien</Link>
                                        </li>
                                        <li>
                                            <Link to={"/entreprise"}>L'entreprise</Link>
                                        </li>
                                        <li>
                                            <Link to={"/contact"}>Contact</Link>
                                        </li>
                                    </ul>
                                </nav>
                                <nav className="menusb sliding-menu" style={{ height: '216px' }}>
                                    <div className="sliding-menu-wrapper" style={{ height: '300px' }}>
                                        <ul id="menu-panel-oowxm" className="menu-panel-root" style={{ height: '300px' }}>
                                            <li>
                                                <Link to={"/"}>Accueil</Link>
                                            </li>
                                            <li>
                                                <Link to={"/louer"}>Louer</Link>
                                            </li>
                                            <li>
                                                <Link to={"/acheter"}>Acheter</Link>
                                            </li>
                                            <li>
                                                <Link to={"/recherche-avancee"}>Recherche de bien</Link>
                                            </li>
                                            <li>
                                                <Link to={"/entreprise"}>L'entreprise</Link>
                                            </li>
                                            <li>
                                                <Link to={"/contact"}>Contact</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>) : ('')}
                    </div>
                </div>
            </header>


        )
    }
}

export default Menu;